<template>
  <div class="banner-config-component app-container">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler">{{
          this.$t("commons.addNew")
        }}</el-button>
        <el-tooltip class="item" effect="dark" :content="$t('commons.multipleSelectionOne')" placement="top">
        </el-tooltip>
      </span>
    </div>
    <div>
      <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" >
        <template v-slot:projectIdSlot="row">
          <span> {{ row.datas.projectName }}</span>
        </template>
      </finalTable>
    </div>

    <!-- 新增/编辑 dialog -->
    <el-dialog :visible.sync="bannerObj.dialogVisible" :before-close="dialogCloseHandler" width="900px"
      :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <template slot="title">
        <span>{{ bannerObj.dialogTitle }}</span>
      </template>
      <bannerDialog v-if="bannerObj.dialogVisible" :bannerObj="bannerObj" @close="dialogCloseHandler"
        @updateSucesss="updateSucesss" />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
// import {
//   findBannerByParam,
//   bannerSort,
// } from "@/api/ruge/gsPark/customerService/bannerConfig";
import {
  findBannerByParam,
  bannerSort,
  delBanner
} from "@/api/ruge/lego/informationDelivery/bannerConfig"
import { getProjectList } from "@/api/business/base/tenant/map";
import bannerDialog from "./bannerDialog.vue";
export default {
  components: {
    finalTable,
    bannerDialog,
  },
  data() {
    return {
      bannerObj: {
        dialogVisible: false,
        dialogTitle: "新增图片",
        dialogStatus: "add",
        id: "",
      },
      dataset: {
        paginationConfig: {
          need: false,
        },
        filterLine: {
          show: false,
        },
        selection: {
          need: false,
          width: "18",
        },
        header: [
          { prop: "projectId", label: "项目名称", width: "" },
          { prop: "fileName", label: "图片标题", width: "" },
          { prop: "fileLink", label: "图片链接", width: "" },
          { prop: "linkType", label: "链接类型", width: "" },
          { prop: "updateBy", label: "发布人", width: "" },
          { prop: "updateTime", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "100" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          fileName: {
            type: "input",
            label: "图片标题",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入图片标题",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          projectId: {
            type:'slot',
            slotName: 'projectIdSlot'
          },
          updateTime: {
            type: "dateFormat",
          },
          linkType: {
            type: "enumerationColumn",
            emuList: {
              0: "无链接",
              1: "内部链接",
              2: "外部链接",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "删除",
              },
              // {
              //   actionType: "iconClick",
              //   eventName: "edit",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-edit",
              //   tooltips: "编辑",
              // },
              // {
              //   actionType: "iconClick",
              //   eventName: "upHandler",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-top",
              //   tooltips: "上移",
              // },
              // {
              //   actionType: "iconClick",
              //   eventName: "downHandler",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-bottom",
              //   tooltips: "下移",
              // },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          // createTime: {
          //   type: "dateRange",
          //   label: "创建时间",
          //   value: [],
          // },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  created() {
    this.getTableData();
    this.getProjectList()
  },
  methods: {
    updateSucesss() {
      this.$message.success("更新成功！");
      this.bannerObj.dialogVisible = false;
      this.getTableData();
    },
    moveHandler(type, { sort, id }) {
      if (type === "up" && sort === 1) {
        this.$message.warning("已经是第一条了！");
        return;
      }
      if (type === "down" && sort === 3) {
        this.$message.warning("已经是最后一条了！");
        return;
      }
      bannerSort({
        id,
        sort,
        type,
      }).then((res) => {
        if (res && res.code && res.code === 200) {
          this.getTableData();
          this.$message.success("更新成功！");
        } else {
          this.$message.warning("移动失败: " + res.msg);
        }
      });
    },
    getTableData() {
      findBannerByParam().then((res) => {
        this.dataset.tableData = res.rows;
      });
    },
    getProjectList() {
      getProjectList().then(res => {
        let compantList = []
        res.forEach(item => {
          compantList.push({
            label: item.projectName,
            value: item.projectId
          })
        })
        this.bannerObj.projectList = compantList
      })
    },
    addNewHandler() {
      this.bannerObj.dialogTitle = "新增图片";
      this.bannerObj.dialogStatus = "add";
      this.bannerObj.dialogVisible = true;
      this.bannerObj.fileId = '';
      this.bannerObj.fileLink = '';
      this.bannerObj.fileName = '';
      this.bannerObj.linkType = 1;
      this.bannerObj.projectId = null

    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.getTableData();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.bannerObj.dialogTitle = "编辑banner图片";
            this.bannerObj.dialogStatus = "edit";
            this.bannerObj = { ...this.bannerObj, ...datas.row };
            this.bannerObj.dialogVisible = true;
            break;
          case "view":
            this.bannerObj.dialogTitle = "banner图片详情";
            this.bannerObj.dialogStatus = "view";
            this.bannerObj = { ...this.bannerObj, ...datas.row };
            this.bannerObj.dialogVisible = true;
            break;
          case "upHandler":
            this.moveHandler("up", datas.row);
            break;
          case "downHandler":
            this.moveHandler("down", datas.row);
            break;
          case 'delete':
            this.deleteItem(datas.row)
        }
      }
    },
    deleteItem(row){
      console.log(row);
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          delBanner([row.id]).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getTableData();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    dialogCloseHandler(freshFlag) {
      this.bannerObj.dialogVisible = false;
      freshFlag && this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.banner-config-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
    .not-allowed-primary-button {
      cursor: not-allowed;
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>