var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner-config-component app-container" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addNewHandler } },
              [_vm._v(_vm._s(this.$t("commons.addNew")))]
            ),
            _c("el-tooltip", {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("commons.multipleSelectionOne"),
                placement: "top",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "projectIdSlot",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.datas.projectName))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.bannerObj.dialogVisible,
            "before-close": _vm.dialogCloseHandler,
            width: "900px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.bannerObj, "dialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.bannerObj.dialogTitle))]),
          ]),
          _vm.bannerObj.dialogVisible
            ? _c("bannerDialog", {
                attrs: { bannerObj: _vm.bannerObj },
                on: {
                  close: _vm.dialogCloseHandler,
                  updateSucesss: _vm.updateSucesss,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }