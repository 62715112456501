<template>
  <div class="banner-dialog-component">
    <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
      class="demo-ruleForm">
      <el-row :gutter="48">
        <el-col :span="12" v-if="currentState === 'add'">
          <el-form-item label="项目名称" prop="projectId">
            <el-select  style="width: 100%" v-model="ruleForm.projectId">
              <el-option v-for="(item, index) in bannerObj.projectList" :value="item.value" :label="item.label"
                :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图片标题" prop="fileName">
            <el-input :disabled="currentState === 'view'" maxlength="50" v-model="ruleForm.fileName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="链接类型" prop="linkType">
            <el-select :disabled="currentState === 'view'" style="width: 100%" v-model="ruleForm.linkType">
              <el-option v-for="(item, index) in linkTypeList" :value="item.value" :label="item.label"
                :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="ruleForm.linkType !== 0" label="图片链接" prop="fileLink">
            <el-input :disabled="currentState === 'view'" v-model="ruleForm.fileLink"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="currentState !== 'add'">
          <el-form-item label="发布人" prop="updateBy">
            <el-input disabled v-model="ruleForm.updateBy"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="banner图片" prop="photoIdListId" class="auto-height-item">
          <div class="upload-container" v-loading="uploadLoading">
            <el-upload :disabled="currentState === 'view'" :style="{
      cursor: currentState === 'view' ? 'default' : 'pointer',
    }" class="avatar-uploader" :action="uploadPath" :with-credentials="true" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" @click.stop="handlePictureCardPreview" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips-container">
              <div>
                <div>
                  温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                </div>
                <div>图片尺寸 750*384 像素</div>
              </div>
              <div v-show="currentState !== 'view' && ruleForm.fileId" class="delete-icon" @click="deleteHandler">
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-row>
      <el-form-item align="right" v-if="currentState !== 'view'">
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="resetForm()">取消</el-button>
      </el-form-item>
      <el-form-item align="right" v-if="currentState === 'view'">
        <el-button @click="changeStateToEdit" type="primary">编辑</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" :modal="false" :close-on-press-escape="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { findProfile } from "@/api/ruge/sso/personal/personal.js";

import {
  editBanner,
  addBanner
} from "@/api/ruge/lego/informationDelivery/bannerConfig"
// import { editBanner } from "@/api/ruge/gsPark/customerService/bannerConfig";

export default {
  name: "bannerDialogComponent",
  props: {
    bannerObj: {
      type: Object,
      required: true,
    },
  },
  watch: {
    bannerObj: {
      handler(val) {
        this.currentState = val.dialogStatus;
        this.projectList = val.projectList
        this.ruleForm = { ...this.ruleForm, ...val };
        console.log("this.ruleForm", this.ruleForm);
        if (val.dialogStatus !== 'add') {
          this.ruleForm.photoIdListId.push(val.fileId);
          this.imageUrl =
            envInfo.bgApp.archivePath +
            "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
            val.fileId;
          this.initCurrentUserName();
        }
      },
      immediate: true,
    },
  },
  data() {
    const fileLinkValid = (rule, value, callback) => {
      if (this.ruleForm.linkType !== 0 && !value) {
        callback(new Error("请填写图片链接！"));
        return;
      }
      callback();
    };
    const validatorPhoto = (rule, value, callback) => {
      if (this.currentState === "view") {
        callback();
        return;
      }
      if (this.ruleForm.photoIdListId.length === 0) {
        callback(new Error("banner图片为必填"));
      } else {
        callback();
      }
    };
    return {
      imageUrl: null,
      dialogImageUrl: "",
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      uploadLoading: false,
      currentState: "view",
      dialogVisible: false,
      stateList: [
        { value: "enable", label: "启用" },
        { value: "disable", label: "禁用" },
      ],
      linkTypeList: [
        { value: 0, label: "无链接" },
        { value: 1, label: "内部链接" },
        { value: 2, label: "外部链接" },
      ],
      projectList: [],
      ruleForm: {
        fileName: "",
        fileId: null,
        fileLink: "",
        linkType: 0,
        updateBy: "",
        photoIdListId: [],
        projectId:""
      },
      rules: {
        fileName: [
          { required: true, message: "图片标题为必填项", trigger: "blur" },
        ],
        fileLink: [
          { required: true, validator: fileLinkValid, trigger: "blur" },
        ],
        photoIdListId: [{ required: true, validator: validatorPhoto }],
        projectId: [
          { required: true, message: "项目为必填项", trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      const { fileName, fileId, fileLink, linkType, id, updateBy, sort, projectId } =
        this.ruleForm;
      const params = {
        id,
        fileName,
        fileId,
        fileLink: linkType === 0 ? "" : fileLink,
        linkType,
        updateBy,
        sort,
      };
      const addParams = {
        fileName,
        fileId,
        linkType,
        fileLink: linkType === 0 ? "" : fileLink,
        projectId
      }
      console.log("提交数据：", params);
      if ( this.currentState == 'edit') {
        editBanner(params).then((res) => {
          if (res && res.code && res.code === 200) {
            this.$emit("updateSucesss");
          }
        });
      } else if(this.currentState == 'add') {
        console.log(addParams,'addParams');
        addBanner(addParams).then(res => {
          if (res && res.code && res.code === 200) {
            this.$emit("updateSucesss");
          }
        })
      }
    },
    changeStateToEdit() {
      this.currentState = "edit";
    },
    initCurrentUserName() {
      findProfile({ userAccount: this.$store.getters.userAccount }).then(
        (r) => {
          this.ruleForm.updateBy = r.userName;
        }
      );
    },
    deleteHandler() {
      this.ruleForm.photoIdListId = [];
      this.imageUrl = null;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(files) {
      this.ruleForm.photoIdListId = [files[0].fileId];
      this.ruleForm.fileId = files[0].fileId;
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.uploadLoading = false;
      this.$refs.ruleForm.validateField("photoIdListId");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadLoading = returnFlag;
      return returnFlag;
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="less" scoped>
.banner-dialog-component {
  .upload-container {
    display: flex;

    .tips-container {
      margin-left: 15px;
      font-size: 12px;

      &>div {
        height: 20px;
        line-height: 20px;
      }

      .delete-icon {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
      }
    }
  }

  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>