import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * banner list - 分页查询
 * @param params
 */
export function findBannerByParam(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/banner/findBannerByParam',
    method: 'get',
    params,
  });
}

/**
 * banner list - sort
 * @param params
 */
export function bannerSort(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/banner/sort',
    method: 'get',
    params,
  });
}

/**
 * banner list - update
 * @param params
 */
export function editBanner(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/banner/editBanner',
    method: 'post',
    data: params,
  });
}

/**
 * banner list - add
 * @param params
 */
export function addBanner(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/banner/addBanner',
    method: 'post',
    data: params,
  });
}
/**
 * banner list - delete
 * @param params
 */
export function delBanner(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/banner/delBanner',
    method: 'post',
    data: params,
  });
}
